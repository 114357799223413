
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
            <v-layout row wrap px-4 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.qist.list_path">
                        <template v-slot:item="{item}">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                {{ item.title }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3></v-flex>
            </v-layout>
            <v-layout row wrap mt-5>
                <v-flex xs12>
                    <v-card>
                        <v-card-text>
                            <v-data-table :headers="headers"   v-model="selected_rows"    :search="search" :items="rows" class="elevation-0"
                                item-key="qist_id">
                                <template v-slot:[`item.qist_date`]="{ item }">
                                    <div>
                                        {{ new Date(item.qist_date).toISOString().split('T')[0] }}
                                    </div>
                                </template>
                                <template v-slot:[`item.paid_date`]="{ item }">
                                    <div>
                                        {{ new Date(item.paid_date).toISOString().split('T')[0] }}
                                    </div>
                                </template>
                                <template v-slot:[`item.qist_amount`]="{ item }">
                                    <div>
                                        {{ (item.qist_amount).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }}
                                    </div>
                                </template>
                                <template v-slot:[`item.qist_status`]="{ item }">
                                    <div>
                                        <v-chip small color="success" v-if="item.qist_status == 'paid'"> Paid </v-chip>
                                        <v-chip small color="error" v-if="item.qist_status == 'not_paid'"> Not Paid </v-chip>
                                    </div>
                                </template>
                                <template v-slot:[`item.qist_id`]="{ item }">
                                    <div>
                                        <v-btn icon :to="'/qist-list/'+item.qist_id"  color="teal" class="mx-1">
                                        <v-icon> mdi-pencil-outline </v-icon>
                                        </v-btn>
                                        <v-btn color="error" icon class="mx-1" @click="selectQist(item)" >
                                            <v-icon> mdi-delete-outline </v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card-text>
                        
                        <v-card-actions v-if="selected_rows.length > 0">
                            <v-btn color="error" @click="deleteQistList">{{$store.getters.language.data.qist.delete_list_btn}}</v-btn>
                        </v-card-actions>
                            
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <v-dialog v-model="delete_dialog" persistent max-width="400">
            <v-card>
                <v-card-title>
                    {{$store.getters.language.data.qist.delete_question}}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text class="elevation-0" @click="delete_dialog = false">
                        {{$store.getters.language.data.qist.cancel_btn}}
                    </v-btn>
                    <v-btn color="error" class="elevation-0" @click="deleteQist(selected_qist.qist_id)">
                        {{$store.getters.language.data.qist.yes_btn}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import requests from './../../requests/qist.request.js'
    export default {
        data() {
            return {
                qist: {},
                search: '',
                loading : true,
                loading_btn:false,
                invoice_id: null,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows:[],
                selected_qist : {},
                delete_dialog: false,
                headers: [
                    { 
                        text: this.$store.getters.language.data.qist.invoice_id,
                        align: 'start',
                        sortable: true,
                        value: 'invoice_id',
                    },
                    { 
                        text: this.$store.getters.language.data.qist.qist_date,
                        align: 'start',
                        sortable: true,
                        value: 'qist_date',
                    },
                    { 
                        text: this.$store.getters.language.data.qist.paid_date,
                        align: 'start',
                        sortable: true,
                        value: 'paid_date',
                    },
                    { 
                        text: this.$store.getters.language.data.qist.qist_status,
                        align: 'start',
                        sortable: true,
                        value: 'qist_status',
                    },
                    { 
                        text: this.$store.getters.language.data.qist.user_name,
                        align: 'start',
                        sortable: true,
                        value: 'user_name',
                    },
                    { 
                        text: this.$store.getters.language.data.qist.qist_amount,
                        align: 'start',
                        sortable: true,
                        value: 'qist_amount',
                    },
                    { 
                        text: this.$store.getters.language.data.qist.qist_note,
                        align: 'start',
                        sortable: true,
                        value: 'qist_note',
                    },   
                    {
                        text: this.$store.getters.language.data.actions,
                        align: 'start',
                        sortable: true,
                        value: 'qist_id',
                    }
                ],
            }
        },
        computed: {
            
            invoices(){
                return this.$store.getters.invoices_list
            },
            
            users(){
                return this.$store.getters.users_list
            },
                    
            
        },
        mounted(){
            this.invoice_id = this.$route.params.invoice_id
            this.readQist();
        },
        methods: {
            addQist() {
                this.loading_btn = true
                requests.createQist(this.qist).then(r => {
                    if (r.status == 200) {
                        this.qist = {}
                        this.rows.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'Qist Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add Qist',
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteQist(qist_id) {
                requests.deleteQist(qist_id).then(r => {
                    this.delete_dialog = false
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{
                            return f.qist_id != qist_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'Qist Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            deleteQistList() {
                let ids = this.selected_rows.map(m => m.qist_id)
                requests.deleteQistList(ids).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{  
                            return ids.indexOf(f.qist_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.selected_rows.length+' Qist Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            readQist() {
                this.loading = true
                requests.getQistByColumn('invoice_id', this.invoice_id).then(r => {
                    if (r.status == 200) {
                        this.rows = r.data.rows
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Qist',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read Qist',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            selectQist(i) {
                this.selected_qist = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    